import { initFederation } from '@angular-architects/module-federation';
/* eslint-disable */
import('./bootstrap').catch(err => console.error(err));

initFederation('/assets/mf.manifest.json')
/* eslint-disable */
  .catch(err => console.error(err))
  .then(_ => import('./bootstrap'))
  .catch(err => console.error(err));

